var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    {
      staticClass: "black grey--text",
      staticStyle: { "z-index": "6" },
      attrs: { app: "", flat: "", dark: "", height: "33px" },
    },
    [
      _c(
        "v-toolbar-title",
        {
          staticStyle: { "font-size": "15px", "margin-right": "100px" },
          attrs: { width: "400" },
        },
        [
          !!(_vm.org && _vm.client)
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.org.name) +
                    " - " +
                    _vm._s(_vm.client.name) +
                    " "
                ),
              ])
            : _vm._e(),
          !!_vm.game
            ? _c("span", { attrs: { id: "game" } }, [
                _vm._v(" > " + _vm._s(_vm.game.name)),
              ])
            : _vm._e(),
        ]
      ),
      _c("v-spacer"),
      _c("v-switch", {
        attrs: { label: "Summon Host", "hide-details": "" },
        model: {
          value: _vm.summonHost,
          callback: function ($$v) {
            _vm.summonHost = $$v
          },
          expression: "summonHost",
        },
      }),
      _c(
        "v-btn",
        {
          staticClass: "grey--text",
          attrs: { flat: "" },
          on: { click: _vm.goToContentPage },
        },
        [
          _c("v-icon", { staticClass: "grey--text", attrs: { left: "" } }, [
            _vm._v("library_books"),
          ]),
          _c("div", { staticClass: "hidden-xs-only" }, [_vm._v("Content")]),
        ],
        1
      ),
      _vm._l(_vm.items, function (item) {
        return _c(
          "v-toolbar-items",
          { key: item.title },
          [
            _c(
              "v-btn",
              {
                staticClass: "grey--text",
                attrs: { to: item.route, flat: "" },
              },
              [
                _c(
                  "v-icon",
                  { staticClass: "grey--text", attrs: { left: "" } },
                  [_vm._v(_vm._s(item.icon))]
                ),
                _c("div", { staticClass: "hidden-xs-only" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }