<template>
  <v-toolbar
    app
    flat
    dark
    class="black grey--text"
    height="33px"
    style="z-index: 6"
  >
    <v-toolbar-title style="font-size: 15px; margin-right: 100px" width="400">
      <span v-if="!!(org && client)"> {{ org.name }} - {{ client.name }} </span>
      <span id="game" v-if="!!game"> > {{ game.name }}</span>
    </v-toolbar-title>
    <v-spacer />
    <v-switch v-model="summonHost" label="Summon Host" hide-details />

    <v-btn class="grey--text" @click="goToContentPage" flat>
      <v-icon class="grey--text" left>library_books</v-icon>
      <div class="hidden-xs-only">Content</div>
    </v-btn>

    <v-toolbar-items v-for="item in items" :key="item.title">
      <v-btn class="grey--text" :to="item.route" flat>
        <v-icon class="grey--text" left>{{ item.icon }}</v-icon>
        <div class="hidden-xs-only">{{ item.title }}</div>
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import { mapGetters } from "vuex"
import { db } from "@/firebase"

import {
  MODULE_NAME as AUTH_MODULE_NAME,
  GetterTypes as AuthGetterTypes
} from "@/store/AuthModule"

export default {
  name: "LegacyToolbar",
  data() {
    return {
      app: null
    }
  },
  created() {
    db.ref(`app`).on("value", this.onAppSnapshot)
  },
  beforeDestroy() {
    db.ref(`app`).off("value", this.onAppSnapshot)
  },
  methods: {
    goToContentPage() {
      window.location.href = "/content"
    },
    onAppSnapshot(snapshot) {
      this.app = snapshot.val()
    }
  },
  computed: {
    ...mapGetters("auth", ["client", "isSuper", "isHost"]),
    ...mapGetters(["orgID"]),
    ...mapGetters({
      org: "theOrg",
      game: "actualGame"
    }),
    isShardSession() {
      return this.$store.getters[
        `${AUTH_MODULE_NAME}/${AuthGetterTypes.IS_SHARD_SESSSION}`
      ]
    },
    isEscapeRoom() {
      return this.client?.escapeRoom
    },
    items() {
      const items = []
      items.push({
        icon: "transit_enterexit",
        title: "Logout",
        route: "/logout"
      })

      if (this.isSuper && !this.isShardSession) {
        items.push({
          icon: "admin_panel_settings",
          title: "Admin",
          route: "/admin"
        })
      }

      if (!this.isShardSession) {
        items.push({
          icon: "golf_course",
          title: "Games",
          route: "/games"
        })
      }

      items.push({
        icon: "accessibility",
        title: "Clients",
        route: "/clients"
      })

      return items
    },
    summonHost: {
      get() {
        return Boolean(this.app?.isSummonHostActive)
      },
      set(value) {
        db.ref(`app/isSummonHostActive`).set(value)
      }
    }
  }
}
</script>
